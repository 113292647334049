import { GalleryItem } from '@app/gallery/gallery.service';
import { GoalSearch } from '@app/goal/goal.service';
import { LevelSearch } from '@app/level/level.service';
import { EnvironmentSearch } from '@app/exercise/exercise.service';
import { ExpectedResult } from '@app/nutrition-program/types';

export interface ProgramSearch {
    id: number;
    name: string;
    is_published: boolean;
    like_count: number;
    avg_rating: string;
    comment_count: number;
    activated_count: number;
    deactivated_count: number;
}

export interface ProgramItemCustomerResponse {
    id: number;
    name: string;
}

export interface ProgramWeekItemResponse {
    id: number;
    program_week_id: number;
    is_locked: boolean;
    is_rest_day: boolean;
    order: number;
    training: Training;
}
export interface Training {
    id: number;
    name: string;
    description: string;
    number_of_calories: number;
    approximate_duration_in_minutes: number;
    parent_id: number;
}
export interface ProgramWeekResponse {
    id: number;
    program_id: number;
    order: number;
    week_items: ProgramWeekItemResponse[];
    description: string;
}

export interface ExpectedResults {
    description: string;
}

export interface ProgramResponse {
    id: number;
    user_id: number;
    name: string;
    display_name: string;
    description: string;
    gender: 'male' | 'female';
    workout_days_per_week: number;
    is_published: boolean;
    is_free: boolean;
    is_tailor_made: boolean;
    is_coming_soon: boolean;
    follows_schedule: boolean;
    parent_program?: Program;
    stripe_product_id?: string;
    stripe_payment_link?: string;
    shopify_product_id?: string;
    image: GalleryItem;
    rest_day_image: GalleryItem;
    video: GalleryItem;
    levels: LevelSearch[];
    exercise_environments: EnvironmentSearch[];
    goals: GoalSearch[];
    expected_results: ExpectedResults[];
    program_weeks: ProgramWeekResponse[];
    muscle_groups: MuscleGroup[];
    documents: any;
    price: string;
    currency_code: string;
    questionnaire?: Questionnaire;
    include_in_survey?: boolean;
    only_with_one_time_payment?: boolean;
    package_id?: number;
    number_of_renewals?: number;
    continue_renewing?: boolean;
    notify_coach_on_purchase?: boolean;
    program_packages?: ProgramPackage[];
}

export interface ProgramPackage {
    package_id: number;
    automatic_activation: boolean;
}

export interface MuscleGroup {
    id: number;
    name: string;
}

export interface WeekItem {
    is_locked: boolean;
    training_id?: number;
    make_copy?: boolean;
    name: string;
    is_rest_day: boolean;
}

export interface ProgramWeek {
    id?: number;
    description: string;
    items: WeekItem[];
}

export interface Program {
    id?: number;
    name: string;
    display_name: string;
    description: string;
    workout_days_per_week: number;
    level_ids: number[];
    goal_ids: number[];
    exercise_environment_ids: number[];
    is_published: boolean;
    is_free: boolean;
    is_tailor_made: boolean;
    is_coming_soon: boolean;
    follows_schedule: boolean;
    parent_program?: Program;
    stripe_product_id?: string;
    stripe_payment_link?: string;
    shopify_product_id?: string;
    gender?: 'male' | 'female';
    video_id: number;
    image_id: number;
    image: GalleryItem;
    video: GalleryItem;
    rest_day_image: GalleryItem;
    expected_results: ExpectedResult[];
    weeks: ProgramWeek[];
    muscle_groups: MuscleGroup[];
    documents?: any;
    price?: string;
    currency_code?: string;
    questionnaire?: Questionnaire;
    include_in_survey: boolean;
    one_time_purchase?: boolean;
    only_with_one_time_payment?: boolean;
    package_id?: number;
    number_of_renewals?: number;
    continue_renewing?: boolean;
    notify_coach_on_purchase?: boolean;
    program_packages?: ProgramPackage[];
}

interface Questionnaire {
    description: string;
    id: number;
    name: string;
}
