import { Injectable } from '@angular/core';

import { LevelService, LevelSearch } from '@app/level/level.service';
import {
    EnvironmentService,
    EnvironmentSearch,
} from '@app/environment/environment.service';
import { GoalService, GoalSearch } from '@app/goal/goal.service';
import { Customer } from '@app/user/user.service';
import { FitApiClientService, FitList } from '@shared/fit-api-client.service';

import { Program, ProgramResponse, ProgramSearch, MuscleGroup, ProgramPackage } from './types';
import { MuscleGroupService } from '@app/muscle-group/muscle-group.service';

export { LevelSearch, EnvironmentSearch, GoalSearch, Customer, MuscleGroup };

export interface User {
    apple_id: string | number;
    bio: null;
    cover_image_id: null;
    created_at: string;
    date_of_birth: string;
    dietary_goal_id: string | number;
    email: string;
    email_verified_at: string;
    exercise_environment_id: string | number;
    firebase_id: string;
    first_name: string;
    food_preference_id: string | number;
    gender: string;
    goal_id: string | number;
    google_id: string;
    has_seen_welcome_message: true;
    height_in_centimeters: string | number;
    id: number;
    image_id: number;
    is_active: boolean;
    is_survey_completed: boolean;
    last_name: string;
    level_id: string | number;
    metric_unit: string;
    phone_number: string | number;
    timezone: string;
    updated_at: string;
    weight_in_kilograms: string | number;
}

export interface WeekItemRequest {
    is_locked: boolean;
    order: number;
    training_id: number;
    is_rest_day: boolean;
}

export interface ProgramWeekRequest {
    order: number;
    description: string;
    program_week_items: WeekItemRequest[];
}

export interface ProgramRequest {
    name: string;
    display_name: string;
    description: string;
    gender: 'male' | 'female';
    workout_days_per_week: number;
    video_id: number;
    image_id: number;
    is_published: boolean;
    is_free: boolean;
    is_tailor_made: boolean;
    is_coming_soon: boolean;
    follows_schedule: boolean;
    parent_id?: number;
    shopify_product_id?: string;
    level_ids: number[];
    exercise_environment_ids: number[];
    goal_ids: number[];
    expected_results: string[];
    program_weeks: ProgramWeekRequest[];
    rest_day_image_id: number;
    muscle_group_ids: number[];
    gallery_ids: any;
    price?: string;
    currency_code?: string;
    questionnaire_id?: number;
    include_in_survey: boolean;
    only_with_one_time_payment?: boolean;
    package_id?: number;
    number_of_renewals?: number;
    continue_renewing?: boolean;
    notify_coach_on_purchase?: boolean;
    program_packages?: ProgramPackage[];
}

@Injectable({ providedIn: 'root' })
export class ProgramService {
    constructor(
        private fitApi: FitApiClientService,
        private levelService: LevelService,
        private environmentService: EnvironmentService,
        private goalService: GoalService,
        private muscleGroupsService: MuscleGroupService
    ) { }

    public search(
        query: string,
        page: number,
        is_tailor_made: boolean = false,
        one_time_payment_only: boolean = false,
        order
    ) {
        const params: any = {
            search: query,
            page: page.toString(),
            is_tailor_made,
            one_time_payment_only,
            order
        };
        return this.fitApi.get<FitList<ProgramSearch>>(
            '/admin/program',
            params
        );
    }

    public searchPublished(
        query: string,
        page: number,
        is_tailor_made: boolean = false
    ) {
        const params: any = {
            search: query,
            page: page.toString(),
            is_tailor_made,
            is_published: true
        };
        return this.fitApi.get<FitList<ProgramSearch>>(
            '/admin/program',
            params
        );
    }

    public get(id: number) {
        return this.fitApi.get<ProgramResponse>('/admin/program/' + id);
    }

    public getProgramUsers(programId: number) {
        return this.fitApi.get<Customer[]>(`/admin/program/${programId}/user`);
    }

    public create(request: ProgramRequest) {
        return this.fitApi.put<ProgramResponse>('/admin/program/0', request);
    }

    public update(requestId: number, request: ProgramRequest) {
        return this.fitApi.put<ProgramResponse>(
            '/admin/program/' + requestId,
            request
        );
    }

    public delete(id: number) {
        return this.fitApi.delete<any>('/admin/program/' + id);
    }

    public deactivateForAll(id: number) {
        return this.fitApi.put<any>(`/admin/program/${id}/deactivate`, null);
    }

    public reactivateForAll(id: number) {
        return this.fitApi.put<any>(`/admin/program/${id}/reactivate`, null);
    }

    public searchLevels(query: string, page: number) {
        return this.levelService.search(query, page);
    }

    public searchEnvironments(query: string, page: number) {
        return this.environmentService.search(query, page);
    }

    public searchGoals(query: string, page: number) {
        return this.goalService.search(query, page);
    }

    public searchMuscleGroups(query: string, page: number) {
        return this.muscleGroupsService.search(query, page);
    }

    public publishProgram(programId: number) {
        return this.fitApi.put<any>(`/admin/program/${programId}/publish`, null);
    }

    public assignProgramToUser(programId: number, userId: number) {
        return this.fitApi.put<any>(`/admin/program/${programId}/user/${userId}`, undefined);
    }

    public assignTailorProgramToUser(
        programId: number,
        userId: number,
        price: number,
        currencyCode: string
    ) {
        return this.fitApi.post<any>(`/admin/program/${programId}/user/${userId}/paid`, {
            program: programId,
            user: userId,
            price: `${price}`,
            currencyCode
        }
        );
    }

    public unassignUserFromTailorProgram(program: Program, user: User) {
        return this.fitApi.post<any>(`/admin/program/${program.id}/user/${user.id}/deactivate`, {});
    }

    public genenerateUniqueID() {
        return Date.now();
    }

    public copyToProgramOrTailor(program: Program, params) {
        return this.fitApi.put<any>(`/admin/program/${program.id}/copy`, null, params);
    }

    public mapToProgramRequest(program: Program): ProgramRequest {
        return {
            name: program.name,
            display_name: program.display_name,
            description: program.description,
            exercise_environment_ids: program.exercise_environment_ids,
            level_ids: program.level_ids,
            goal_ids: program.goal_ids,
            image_id: program.image.id,
            video_id: program.video ? program.video.id : null,
            rest_day_image_id: program.rest_day_image
                ? program.rest_day_image.id
                : undefined,
            is_free: program.is_free,
            is_tailor_made: program.is_tailor_made,
            is_coming_soon: program.is_coming_soon,
            follows_schedule: program.follows_schedule,
            parent_id: program.parent_program ? program.parent_program.id : null,
            shopify_product_id: program.shopify_product_id,
            gender: program.gender,
            is_published: program.is_published,
            expected_results: program.expected_results.map(
                (e) => e.description
            ),
            muscle_group_ids: program.muscle_groups.map((mg) => mg.id),
            gallery_ids: program.documents.map(item => item.id),
            program_weeks: program.weeks.map((w, i) => {
                return {
                    order: i + 1,
                    description: w.description,
                    program_week_items: w.items
                        ? w.items.map((item, index) => {
                            return {
                                is_locked: item.is_locked,
                                order: index + 1,
                                training_id: item.training_id,
                                make_copy: item.make_copy ?? false,
                                is_rest_day: item.is_rest_day,
                            };
                        })
                        : [],
                };
            }),
            workout_days_per_week: program.workout_days_per_week,
            price: program.one_time_purchase ? program.price : null,
            questionnaire_id: program.questionnaire ? program.questionnaire.id : null,
            include_in_survey: program.include_in_survey ?? false,
            currency_code: program.one_time_purchase ? program.currency_code : null,
            only_with_one_time_payment: program.only_with_one_time_payment,
            package_id: program.package_id,
            number_of_renewals: program.number_of_renewals,
            continue_renewing: program.continue_renewing,
            notify_coach_on_purchase: program.notify_coach_on_purchase,
            program_packages: program.only_with_one_time_payment ? program.program_packages : []
        };
    }
}
